import { SWITCH_LANGUAGE } from "constants/ActionTypes";
import {
  NAV_STYLE,
  NAV_STYLE_FIXED,
} from "../../constants/ThemeSetting";

const initialSettings = {
  navStyle: NAV_STYLE_FIXED,

  isDirectionRTL: false,
  locale: {
    languageId: 'portuguese',
    locale: 'pt',
    name: 'Português',
    icon: 'br'
  }
};

const settings = (state = initialSettings, action) => {
  switch (action.type) {
    case NAV_STYLE:
      return {
        ...state,
        navStyle: action.navStyle
      };
    default:
      return state;
  }
};

export default settings;
