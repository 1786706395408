import React from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import UserProfile from "./UserProfile";
// import AppsNavigation from "./AppsNavigation";

const SidebarContent = () => {
  const { pathname, isShowClientsView, isShowReceitasView } = useSelector(({common}) => common);
  const { level } = useSelector(({auth}) => auth);

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split('/')[1];

  return (<>

      <SidebarLogo/>
      <div className="gx-sidebar-content">
        <div className="gx-sidebar-notifications">
          <UserProfile/>
          {/* <AppsNavigation/> */}
        </div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            id="sidebar-menu"
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme="dark"
            mode="inline">
              <React.Fragment>
                {/* <Menu.Item key="dashboard">
                  <Link to="/dashboard"><i className="icon icon-widgets"/>
                    <span>Painel</span>
                  </Link>
                </Menu.Item> */}

                {/* <Menu.Item key="commission-statement">
                  <Link to="commission-statement">
                    <i className="icon icon-table"/>
                    <span>Demonstrativo</span>
                  </Link>
                </Menu.Item> */}
                {level === 'N1' &&
                <>
                <Menu.Item key="admin.produtos">
                  <Link to="/admin/produto">
                    <i className="icon icon-table"/>
                    <span>Comissão por Produto</span>
                  </Link>
                </Menu.Item>
                {<Menu.Item key="admin.clientes">
                  <Link to="/admin/cliente">
                    <i className="icon icon-table"/>
                    <span>Comissão por Cliente</span>
                  </Link>
                </Menu.Item>}
                <Menu.Item key="admin.resumo">
                  <Link to="/admin/resumo">
                    <i className="icon icon-table"/>
                    <span>Comissão por Assessor</span>
                  </Link>
                </Menu.Item>
                </>}
                {level === 'N2' &&
                <>
                <Menu.Item key="filiais.produtos">
                  <Link to="/filiais/produto">
                    <i className="icon icon-table"/>
                    <span>Comissão por Produto</span>
                  </Link>
                </Menu.Item>
                {<Menu.Item key="filiais.clientes">
                  <Link to="/filiais/cliente">
                    <i className="icon icon-table"/>
                    <span>Comissão por Cliente</span>
                  </Link>
                </Menu.Item>}
                <Menu.Item key="filiais.resumo">
                  <Link to="/filiais/resumo">
                    <i className="icon icon-table"/>
                    <span>Comissão por Assessor</span>
                  </Link>
                </Menu.Item>
                </>}
                {level === 'N3' &&
                <>
                  <Menu.Item key="equipe.produtos">
                    <Link to="/equipe/produto">
                      <i className="icon icon-table"/>
                      <span>Comissão por Produto</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="equipe.resumo">
                    <Link to="/equipe/resumo">
                      <i className="icon icon-table"/>
                      <span>Comissão por Assessor</span>
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="equipe.clientes">
                    <Link to="/equipe/cliente">
                      <i className="icon icon-table"/>
                      <span>Comissão por Cliente</span>
                    </Link>
                  </Menu.Item>
                  {/* {isShowClientsView &&
                    <Menu.Item key="equipe.clientes">
                      <Link to="/equipe/cliente">
                        <i className="icon icon-table"/>
                        <span>Comissão por Cliente</span>
                      </Link>
                    </Menu.Item>} */}
                </>}
                {level === 'N4' &&
                <>
                  <Menu.Item key="assessor.produtos">
                    <Link to="/assessor/produto">
                      <i className="icon icon-table"/>
                      <span>Comissão por Produto</span>
                    </Link>
                  </Menu.Item>
                  {
                    <Menu.Item key="assessor.clientes">
                      <Link to="/assessor/cliente">
                        <i className="icon icon-table"/>
                        <span>Comissão por Cliente</span>
                      </Link>
                    </Menu.Item>}
                  {/* {<Menu.Item key="assessor.clientes">
                    <Link to="/assessor/receita-per-cliente">
                      <i className="icon icon-table"/>
                      <span>Receita por Cliente</span>
                    </Link>
                  </Menu.Item>} */}
                </>}
                <Menu.Item key="manual">
                      <a target="_blank" href="https://montebravocorp.sharepoint.com/Documentos%20Compartilhados/Forms/Lista.aspx?FolderCTID=0x012000CEA6DAA206C1974F98F883EE63EB8260&id=%2FDocumentos%20Compartilhados%2FHub%20de%20assessoria%2FFAQ%20Comiss%C3%B5es%2FFAQ%20COMISS%C3%95ES%2Epdf&parent=%2FDocumentos%20Compartilhados%2FHub%20de%20assessoria%2FFAQ%20Comiss%C3%B5es">
                        <i className="icon icon-inbox"/>
                        <span>Manual</span>
                      </a>
                </Menu.Item>
              </React.Fragment>
          </Menu>
        </CustomScrollbars>
      </div>
    </>
  );
};

SidebarContent.propTypes = {};

export default SidebarContent;

