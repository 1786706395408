import {
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_LEVEL_SET,
  USER_ID_SET,
  USER_SIGN_OUT,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  initURL: '',
  userInfo: JSON.parse(localStorage.getItem('userInfo')),
  userId: JSON.parse(localStorage.getItem('userId')),
  level: JSON.parse(localStorage.getItem('level'))
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case INIT_URL: {
      return {...state, initURL: action.payload};
    }

    case USER_SIGN_OUT: {
      localStorage.clear();
      return {
        ...state,
        authUser: null,
        userId: null,
        initURL: ''
      }
    }

    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        userId: null,
        initURL: ''
      }
    }

    case USER_DATA: {
      return {
        ...state,
        userInfo: action.payload
      };
    }

    case USER_LEVEL_SET: {
      return {
        ...state,
        level: action.payload,
      }
    }

    case USER_ID_SET: {
      return {
        ...state,
        userId: action.payload,
      };
    }
    
    default:
      return state;
  }
}
