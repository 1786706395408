import axios from 'axios';

const http =  axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`
});

http.interceptors.response.use(
  response => {
    //jwt special refresh
    axios.get(`${process.env.REACT_APP_API_URL}/auth/refresh-token`, {
      headers: {
        Authorization: `Bearer ${JSON.parse(window.localStorage.getItem("token"))}`,
      }
    })
    .then(({data}) => {
      if (data.result) {
        localStorage.setItem("token", JSON.stringify(data.token.access_token))
        rebuildHttp();
      }
    }).catch(function (error) {
      console.log("Error****:", error.message);
    });

    return response;
  },
  error => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href='/';
    }
    return Promise.reject(error);
  }
)

export const rebuildHttp = () => {
  let headers = {}
  let token = JSON.parse(window.localStorage.getItem("token"));

  if (token) {
    headers = {...headers, Authorization: `Bearer ${token}`}
  }

  http.defaults.headers.common = headers
}

rebuildHttp();

export default http;