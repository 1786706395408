import React, { useEffect, useState } from "react";
import { useDispatch, useSelector} from "react-redux";
import { Link } from "react-router-dom";
import { Avatar, Popover } from "antd";
import { userSignOut } from "appRedux/actions/Auth";

const UserInfo = () => {

  const dispatch = useDispatch();

  const user = useSelector(({auth}) => auth.userInfo)
  const [data, setData] = useState([])
  
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li><Link to="/account" className="gx-text-gray">Minha conta</Link></li>
      <li onClick={() => dispatch(userSignOut())}>Sair</li>
    </ul>
  );

  useEffect(() => {
    // dispatch(getUser());
    if (user) {
      setData(user)
    }
  }, [user]);

  // useEffect(() => {
  //   dispatch(getUser());
  // }, []);

  return (
    <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions}
             trigger="click">
      <Avatar src={ data.cenario ? `${process.env.REACT_APP_FTP_URL}/` + data.cenario : "https://via.placeholder.com/150x150" }
              className="gx-avatar gx-pointer" alt=""/>
    </Popover>
  )

}

export default UserInfo;
