import React, { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch, useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { ConfigProvider } from 'antd';

import MainApp from "./MainApp";
import SignIn from "../SignIn";
import { setInitUrl } from "appRedux/actions/Auth";
import locale from 'antd/lib/locale/pt_BR';

const RestrictedRoute = ({component: Component, location, userId, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      userId
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/login',
            state: {from: location}
          }}
        />}
  />;


const App = () => {
  const dispatch = useDispatch();
  const { userId, level, initURL } = useSelector(({auth}) => auth);

  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();


  useEffect(() => {
    let link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = "/css/style.css";
    link.className = 'gx-style';
    document.body.appendChild(link);
  });

  useEffect(() => {
    if (initURL === '') {
      dispatch(setInitUrl(location.pathname));
    }
  }, [dispatch, initURL, location.pathname]);

  useEffect(() => {
    if (location.pathname === '/') {
      if (userId === null) {
        history.push('/login');
      } else {
        if (level === 'N4') {
          history.push('/assessor/produto');
        } else if (level === 'N3') {
          history.push('equipe/produto');
        } else if (level === 'N2') {
          history.push('filiais/produto');
        } else if (level === 'N1') {
          history.push('admin/produto');
        }
      }
    }
    
  }, [userId, level, initURL, location, history]);
  
  return (
    <ConfigProvider locale={locale}>
      <Switch>
        <Route exact path='/login' component={SignIn}/>
        <RestrictedRoute path={`${match.url}`} userId={userId} location={location}
                          component={MainApp}/>
      </Switch>
    </ConfigProvider>
  )
};

export default memo(App);
