import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_LEVEL_SET,
  USER_ID_SET,
  HIDE_CLIENTES_VIEW,
  SHOW_CLIENTES_VIEW,
  HIDE_RECEITAS_VIEW,
  SHOW_RECEITAS_VIEW
} from "../../constants/ActionTypes";
import http, {rebuildHttp} from 'util/Api';
import axios from 'axios';

export const userSignUp = ({email, password, name}) => {

  return (dispatch) => {
    dispatch({type: FETCH_START});
    http.post('auth/register', {
        email: email,
        password: password,
        name: name
      }
    ).then(({data}) => {
      if (data.result) {
        localStorage.setItem("token", JSON.stringify(data.token.access_token));
        dispatch({type: FETCH_SUCCESS});
        dispatch({type: USER_DATA, payload: data.user});
        rebuildHttp();
      } else {
        console.log("payload: data.error", data.error.message);
        dispatch({type: FETCH_ERROR, payload: "Network Error"});
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message});
      console.log("Error****:", error.message);
    });
  }
};

export const userSignIn = ({email, password}) => {
  return async (dispatch) => {
    dispatch({type: FETCH_START});
    axios.post(`${process.env.REACT_APP_API_URL}/auth/login`, {
        email: email,
        password: password,
      }
    ).then(({data}) => {
      if (data.result) {
        let userInfo = {
          cenario: data.user.cenario,
          name: data.user.name,
          nivel_acesso: data.user.nivel_acesso
        }
        localStorage.setItem("token", JSON.stringify(data.token.access_token))
        localStorage.setItem("level", JSON.stringify(data.user.nivel_acesso))
        localStorage.setItem("userId", JSON.stringify(data.user.id))
        localStorage.setItem("userInfo", JSON.stringify(userInfo))
        localStorage.setItem("FIRST_LOGIN", true)

        if (data.user.backoffice__c === 'TRUE' || data.user.sales__c === 'TRUE' || data.user.segmento__c !== 'ASSESSORIA EXCLUSIVA') {
          localStorage.setItem("isShowClientsView", false)
          dispatch({type: HIDE_CLIENTES_VIEW})
        } else {
          localStorage.setItem("isShowClientsView", true)
          dispatch({type: SHOW_CLIENTES_VIEW})
        }

        if (data.user.backoffice__c === 'FALSE' && data.user.sales__c === 'FALSE' && data.user.segmento__c !== 'ASSESSORIA EXCLUSIVA') {
          localStorage.setItem("isShowReceitasView", true)
          dispatch({type: SHOW_RECEITAS_VIEW})
        } else {
          localStorage.setItem("isShowReceitasView", false)
          dispatch({type: HIDE_RECEITAS_VIEW})
        }

        dispatch({type: FETCH_SUCCESS})
        dispatch({type: USER_ID_SET, payload: data.user.id})
        dispatch({type: USER_LEVEL_SET, payload: data.user.nivel_acesso})
        dispatch({type: USER_DATA, payload: userInfo})
        rebuildHttp();
      } else {
        dispatch({type: FETCH_ERROR, payload: data.error.message})
      }
    }).catch(function (error) {
      dispatch({type: FETCH_ERROR, payload: error.message})
      console.log("Error****:", error)
    });
  }
};

export const refreshToken = () => {
  return (dispatch) => {
    http.get('auth/refresh-token')
    .then(({data}) => {
      if (data.result) {
        localStorage.setItem("token", JSON.stringify(data.token.access_token))
        rebuildHttp();
      }
    }).catch(function (error) {
      console.log("Error****:", error.message);
    });
  };
};

export const userSignOut = () => {

  return (dispatch) => {
    localStorage.clear();
    dispatch({type: SIGNOUT_USER_SUCCESS});
  };
};

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};

