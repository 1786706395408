import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Popover } from "antd";
import { userSignOut } from "../../appRedux/actions/Auth";

const UserProfile = () => {
  const dispatch = useDispatch();

  const user = useSelector(({auth}) => auth.userInfo);
  
  const [data, setData] = useState([])

  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li><Link to="/account" className="gx-text-gray">Minha conta</Link></li>
      <li onClick={() => dispatch(userSignOut())}>Sair</li>
    </ul>
  );

  useEffect(() => {
    if (user) {
      setData(user)
    }
  }, [user]);

  return (

    <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
      <Popover placement="bottomRight" content={userMenuOptions} trigger="click">
        <Avatar src={ data.cenario ? `${process.env.REACT_APP_FTP_URL}/` + data.cenario : "https://via.placeholder.com/150x150" }
                className="gx-size-40 gx-pointer gx-mr-3" alt=""/>
        <span className="gx-avatar-name">{data.name}<i
          className="icon icon-chevron-down gx-fs-xxs gx-ml-2"/></span>
      </Popover>
    </div>

  )
};

export default UserProfile;
