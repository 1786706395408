import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  HIDE_CLIENTES_VIEW,
  SHOW_CLIENTES_VIEW,
  HIDE_RECEITAS_VIEW,
  SHOW_RECEITAS_VIEW
} from 'constants/ActionTypes'
import {TOGGLE_COLLAPSED_NAV, WINDOW_WIDTH} from "../../constants/ActionTypes";

const INIT_STATE = {
  error: "",
  loading: false,
  alertMessage: '',
  navCollapsed: true,
  width: window.innerWidth,
  pathname: '/',
  isShowClientsView: JSON.parse(localStorage.getItem('isShowClientsView')),
  isShowReceitasView: JSON.parse(localStorage.getItem('isShowReceitasView')),
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case '@@router/LOCATION_CHANGE': {
      return {
        ...state,
        pathname: action.payload.location.pathname,
        navCollapsed: false
      }
    }
    case WINDOW_WIDTH:
      return {
        ...state,
        width: action.width,
      };
    case TOGGLE_COLLAPSED_NAV: {
      return {
        ...state,
        navCollapsed: action.navCollapsed
      }
    }
    case HIDE_CLIENTES_VIEW: {
      return {
        ...state,
        isShowClientsView: false,
      }
    }

    case SHOW_CLIENTES_VIEW: {
      return {
        ...state,
        isShowClientsView: true,
      }
    }
    case HIDE_RECEITAS_VIEW: {
      return {
        ...state,
        isShowReceitasView: false,
      }
    }

    case SHOW_RECEITAS_VIEW: {
      return {
        ...state,
        isShowReceitasView: true,
      }
    }
    case FETCH_START: {
      return {...state, error: '', alertMessage: '', loading: true};
    }
    case FETCH_SUCCESS: {
      return {...state, error: '', alertMessage: '', loading: false};
    }
    case SHOW_MESSAGE: {
      return {...state, error: '', alertMessage: action.payload, loading: false};
    }
    case FETCH_ERROR: {
      return {...state, loading: false, error: action.payload, alertMessage: ''};
    }
    case HIDE_MESSAGE: {
      return {...state, loading: false, error: '', alertMessage: ''};
    }
    default:
      return state;
  }
}
