import React from "react";
import { Layout } from "antd";
import { Link } from "react-router-dom";
import { toggleCollapsedSideNav } from "../../appRedux/actions/Setting";
import UserInfo from "components/UserInfo";
import Auxiliary from "util/Auxiliary";


import { TAB_SIZE } from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";

const {Header} = Layout;

const Topbar = () => {
  const dispatch = useDispatch();

  const { navStyle } = useSelector(({settings}) => settings);
  const { navCollapsed, width} = useSelector(({common}) => common);

  return (
    <Header>
      {width < TAB_SIZE ?
        <div className="gx-linebar gx-mr-3">
          <i className="gx-icon-btn icon icon-menu"
             onClick={() => {
               dispatch(toggleCollapsedSideNav(!navCollapsed));
             }}
          />
        </div> : null}
      <Link to="/" className="gx-d-block gx-d-lg-none gx-pointer">
        <img alt="" src={require("assets/images/w-logo.png")}/></Link>

      <ul className="gx-header-notifications gx-ml-auto">
        {width >= TAB_SIZE ? null :
          <Auxiliary>
            <li className="gx-user-nav"><UserInfo/></li>
          </Auxiliary>
        }
      </ul>
    </Header>
  );
};

export default Topbar;
