import React, {useState} from 'react'
import Context from './Context'

export default function Provider({children}) {
    const [disabled, setDisabled] = useState(false)
    const CONTEXT_VALUE = {disabled, setDisabled}
  return (
    <Context.Provider value={CONTEXT_VALUE}>{children}</Context.Provider>
  )
}
