// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const KKK = 'KKK';
export const HIDE_CLIENTES_VIEW = 'HIDE_CLIENTES_VIEW';
export const SHOW_CLIENTES_VIEW = 'SHOW_CLIENTES_VIEW';
export const HIDE_RECEITAS_VIEW = 'HIDE_RECEITAS_VIEW';
export const SHOW_RECEITAS_VIEW = 'SHOW_RECEITAS_VIEW';
export const SIGNUP_USER = 'SIGNUP_USER';
export const USER_SIGN_OUT = 'USER_SIGN_OUT';
export const USER_ID_SET = 'USER_ID_SET';
export const USER_LEVEL_SET = 'USER_LEVEL_SET';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_GOOGLE_USER = 'SIGNIN_GOOGLE_USER';
export const SIGNIN_GOOGLE_USER_SUCCESS = 'SIGNIN_GOOGLE_USER_SUCCESS';
export const SIGNIN_FACEBOOK_USER = 'SIGNIN_FACEBOOK_USER';
export const SIGNIN_FACEBOOK_USER_SUCCESS = 'SIGNIN_FACEBOOK_USER_SUCCESS';
export const SIGNIN_TWITTER_USER = 'SIGNIN_TWITTER_USER';
export const SIGNIN_TWITTER_USER_SUCCESS = 'SIGNIN_TWITTER_USER_SUCCESS';
export const SIGNIN_GITHUB_USER = 'SIGNIN_GITHUB_USER';
export const SIGNIN_GITHUB_USER_SUCCESS = 'signin_github_user_success';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const PROFILE_PICTURE_UPLOAD_START = 'profile_picture_upload_start';
export const PROFILE_PICTURE_UPLOAD_SUCCESS = 'profile_picture_upload_success';
export const PROFILE_PICTURE_UPLOAD_FAILED = 'profile_picture_upload_failed';


//Commision const
export const FETCH_COMMISSION_SUCCESS = 'FETCH_COMMISSION_SUCCESS';

