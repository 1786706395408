import React from "react";
import {Route, Switch} from "react-router-dom";
import Provider from "../context/Provider";

import asyncComponent from "util/asyncComponent";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Provider>
      <Switch>
        {/* <Route path={`${match.url}dashboard`} component={asyncComponent(() => import('./DashboardPage'))}/> */}
        <Route path={`${match.url}commission-statement`} component={asyncComponent(() => import('./CommissionPage'))}/>
        <Route path={`${match.url}account`} component={asyncComponent(() => import('./AccountPage'))}/>
        <Route path={`${match.url}assessor/cliente`} component={asyncComponent(() => import('./ClientesPage'))}/>
        <Route path={`${match.url}assessor/produto`} component={asyncComponent(() => import('./ProdutosPage'))}/>
        <Route path={`${match.url}assessor/receita-per-cliente`} component={asyncComponent(() => import('./ReceitasPage'))}/>

        <Route path={`${match.url}filiais/cliente`} component={asyncComponent(() => import('./Filiais/ClientesPage'))}/>
        <Route path={`${match.url}filiais/produto`} component={asyncComponent(() => import('./Filiais/ProdutosPage'))}/>
        <Route path={`${match.url}filiais/resumo`} component={asyncComponent(() => import('./Filiais/ResumoPage'))}/>

        <Route path={`${match.url}equipe/cliente`} component={asyncComponent(() => import('./Equipe/ClientesPage'))}/>
        <Route path={`${match.url}equipe/produto`} component={asyncComponent(() => import('./Equipe/ProdutosPage'))}/>
        <Route path={`${match.url}equipe/resumo`} component={asyncComponent(() => import('./Equipe/ResumoPage'))}/>

        <Route path={`${match.url}admin/cliente`} component={asyncComponent(() => import('./Admin/ClientesPage'))}/>
        <Route path={`${match.url}admin/produto`} component={asyncComponent(() => import('./Admin/ProdutosPage'))}/>
        <Route path={`${match.url}admin/resumo`} component={asyncComponent(() => import('./Admin/ResumoPage'))}/>
      </Switch>
    </Provider>
  </div>
);

export default App;
