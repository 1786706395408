import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Layout } from "antd";
import App from "routes/index";

import Sidebar from "../Sidebar/index";
import Topbar from "../Topbar/index";
import { footerText } from "util/config";

const { Content, Footer } = Layout;

const MainApp = () => {
  const match = useRouteMatch();

  return (
    <Layout className="gx-app-layout">
      <Sidebar/>
      <Layout>
      <Topbar/>
        <Content className="gx-layout-content">
          <App match={match}/>
          <Footer>
            <div className="gx-layout-footer-content">
              {footerText}
            </div>
          </Footer>
        </Content>
      </Layout>
    </Layout>
  )
};
export default MainApp;

