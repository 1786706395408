import React, { useEffect, useState } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { userSignIn } from "../appRedux/actions/Auth";
// import IntlMessages from "util/IntlMessages";
import InfoView from "components/InfoView";
import PasswordPopup from "../components/PasswordPopup/PasswordPopup";

const FormItem = Form.Item;

const SignIn = (props) => {
  const [popupVisible, setPopUpVisible] = useState(false)
  const dispatch = useDispatch();
  const { userId } = useSelector(({auth}) => auth);

  const onFinish = values => {
    dispatch(userSignIn(values));
  };

  useEffect(() => {
    if (userId !== null) {
      props.history.push('/');
    }
  }, [userId, props.history]);

  return (
    <div className="gx-app-login-wrap">
      <div className="gx-app-login-container">
        <div className="gx-app-login-main-content">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-content-bg">
              <img src={require("assets/images/placeholder272x395.png")} alt='Neature'/>
            </div>
            <div className="gx-app-logo-wid">
              {/* <h1>Entrar</h1> */}
            </div>
            <div className="gx-app-logo">
              <img alt="example" src={require("assets/images/logo.png")}/>
            </div>
          </div>
          <div className="gx-app-login-content">
            {
              popupVisible && (<PasswordPopup setVisible={setPopUpVisible} />)
            }
            <Form
              initialValues={{ remember: true }}
              name="basic"
              onFinish={onFinish}
              className="gx-signin-form gx-form-row0">

              <FormItem
                rules={[{ required: true, type: 'email', message: 'A entrada não é um email válido!' }]} name="email">
                <Input placeholder="E-mail" className="gray-color" />
              </FormItem>
              <FormItem
                rules= {[{required: true, message: 'Por favor insira sua senha!'}]}  name="password">
                <Input type="password"  className="gray-color"placeholder="Senha"/>
              </FormItem>
              <FormItem valuePropName="checked">
                <Checkbox className="gray-color">Lembre mim</Checkbox>
                <a onClick={() => setPopUpVisible(!popupVisible)} className="gx-login-form-forgot" to="/custom-views/user-auth/forgot-password">Esqueceu senha</a>
              </FormItem>
              <FormItem>
                <Button type="primary" className="gx-mb-0" htmlType="submit">
                  Entrar
                </Button>
                {/* <span>ou</span> <Link to="/signup">Inscrever-se</Link> */}
              </FormItem>
              {/* <span
                className="gx-text-light gx-fs-sm"> demo user email: 'graciela.simon@montebravo.com.br' and password: 'dev#azure'</span> */}
            </Form>
          </div>
          <InfoView></InfoView>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
