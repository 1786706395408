import React from "react";
import ReactDOM from "react-dom";

import NextApp from './NextApp';
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker';
import reportWebVitals from './reportWebVitals';
// Add this import:
// Add this import:
import { AppContainer } from 'react-hot-loader';

// require('dotenv').config()


// Wrap the rendering in a function:
const render = Component => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <Component/>
    </AppContainer>,
    document.getElementById('root')
  );
};

// Do this once
// registerServiceWorker();
unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Render once
render(NextApp);

// Webpack Hot Module Replacement API
if (module.hot) {
  module.hot.accept('./NextApp', () => {
    render(NextApp);
  });
}
