import React from "react";
import { Modal} from "antd";


const PasswordPopup = ({setVisible}) => {
  return (
    <Modal
      visible={true}
      onOk={() => setVisible(false)}
    onCancel={() => setVisible(false)}
    >
      <h2>Envie um e-mail para <a href="mailto:comissoes@montebravo.com.br">comissoes@montebravo.com.br</a> para alterarmos sua senha.</h2>
    </Modal>
  );
}

export default PasswordPopup;
